import iconWorld from "../../images/icons/styled/first/World.svg";
import iconLike from "../../images/icons/styled/first/CurrencyControl.svg";
import iconCheckStar from "../../images/icons/styled/first/CheckInStar.svg";
import links from "../../utils/links";

import botPaymentsOnlineShopping from "../../images/icons/styled/first/botPaymentsOnlineShopping.svg";
import botPaymentsInfoproducts from "../../images/icons/styled/second/botPaymentsInfoproducts.svg";
import botPaymentGames from "../../images/icons/styled/first/botPaymentGames.svg";
import botPaymentTips from "../../images/icons/styled/second/botPaymentTips.svg";
import botPaymentDelivery from "../../images/icons/styled/first/botPaymentDelivery.svg";

import botPaymentNativeForm from "../../images/icons/styled/second/botPaymentNativeForm.svg";
import botPaymentNoSite from "../../images/icons/styled/second/botPaymentNoSite.svg";
import botPaymentSecurity from "../../images/icons/styled/second/botPaymentSecurity.svg";
import botPaymentAuditory from "../../images/icons/styled/second/botPaymentAuditory.svg";
import botPaymentTestMode from "../../images/icons/styled/second/botPaymentTestMode.svg";

import botPaymentFeaturesShop from "../../images/icons/buttons/botPaymentFeaturesShop.svg";
import botPaymentFeaturesBot from "../../images/icons/buttons/botPaymentFeaturesBot.svg";

export default {
    "seo": {
        "title": "Receive payments in Telegram | Банк 131",
        "description": "Enable payments in Telegram using the Bank 131 payment bot. It’s an excellent solution for companies working in Telegram."
    },
    "pageHeaderBlock": {
        "title": "Accept payments <br />in Telegram",
        "text": "Connect to the payment bot of Bank 131<br />to receive payments in Telegram",
    },

    features: [
        {
            text: "You have a store in Telegram and you want to enable payments?",
            image: botPaymentFeaturesShop
        },
        {
            text: "We have a solution for you – the Bank 131 payment bot",
            image: botPaymentFeaturesBot
        }
    ],

    receivePayments: {
        title: 'Receive payments from all over <br />Russia in the app',
        description: 'A great solution for those who conduct business in Telegram.',
        listCard: [
            {
                "title": 'Online shopping',
                "icon": botPaymentsOnlineShopping,
            },
            {
                "title": 'Digital content',
                "icon": botPaymentsInfoproducts,
            },
            {
                "title": 'Games',
                "icon": botPaymentGames,
            },
            {
                "title": 'Tipping',
                "icon": botPaymentTips,
            },
            {
                "title": 'Food delivery',
                "icon": botPaymentDelivery,
            }
        ],
    },

    "infoCardsBlockSecond": {
        "cardsTitle": "Why our payment bot?",
        "cards": [
            {
                "title": 'Native payment interface',
                "text": 'Customers can purchase goods and services right in Telegram. Russian Visa, MasterCard, <nobr>and MIR cards</nobr> are accepted',
                "icon": botPaymentNativeForm,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Test mode',
                "text": 'You can test how the bot works before integrating it with your store.',
                "icon": botPaymentTestMode,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Secure transactions',
                "text": 'All payments are secured with a level-one <nobr>PCI DSS certificate.</nobr>',
                "icon": botPaymentSecurity,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Outreach',
                "text": 'Over 500 milion people around the world are using Telegram.',
                "icon": botPaymentAuditory,
                "description": '',
                "theme": 'withoutBackground'
            }
        ]
    },

    "stepsBlock": {
      "title": "How to integrate the payment bot?",
      "titleColor": 'second',
      "listColor": 'second',
      "list": [
          {
              "title": 'Open the BANK 131 payment bot in Telegram and press «start»',
              "image": '/botPaymentSteps/step1.png',
          },
          {
              "title": 'Follow the instructions and go to @Botfather; connect your store and choose Bank 131',
              "image": '/botPaymentSteps/step2.png',
          },
          {
              "title": 'Finalize the process in the payment bot and start receiving payments',
              "image": '/botPaymentSteps/step3.png',
          }
      ]
    },

    "submitApplicationBlock": {
        "textSubmit": "Connect"
    }
}

