import React from 'react';
import Layout from "../../layout/Layout";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import SEO from "../../components/seo";

import Block from "../../blocks/Block";
import BlockSection from "../../blocks/BlockSection";
import Title from "../../components/typography/Title";
import StepsListHorizontal from "../../components/StepsListHorizontal";
import SignUpCompanyLeadForm from "../../forms/instances/SignUpCompanyLeadForm/SignUpCompanyLeadForm";
import SimpleCard from "../../components/SimpleCard";
import FeatureCard from "../../components/FeatureCard";
import InfoCardsBlock from "../../blocks/InfoCardsBlock";

import enMessages from '../../i18n/paymentBot/en.js'
import ruMessages from '../../i18n/paymentBot/ru.js'

import '../../styles/pages/botPayment.scss';

const messages = {
    en: enMessages,
    ru: ruMessages
}

const OnlineBankPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />

            <Block config={{ baseBorder: false, animation: 3 }} classNameInner="margin-none">
                <div className="container-tg-payments">
                    <PageHeaderBlock
                        locale={locale}
                        title={messages[locale].pageHeaderBlock.title}
                        text={messages[locale].pageHeaderBlock.text}
                        submitFormCaption={messages[locale].submitApplicationBlock.textSubmit}
                        theme='simple'
                        titleColor='first'
                        config={{
                            headBorder: false,
                        }}
                    />

                    <Block config={{ baseBorder: false, animation: 3 }} classNameInner="margin-none_top">
                        <div id='BotPayment-Features'>
                            <div className="container">
                                    <div id='BotPayment-MainFeatures' class='BotPayment-MainFeatures'>
                                        {messages[locale].features.map((item, index) => {
                                            return <FeatureCard key={index} {...item} />
                                        })}
                                    </div>
                            </div>
                        </div>

                        <div id='BotPayment-ReceivePayments'>
                            <div className="container">
                                <BlockSection className='Flex-column-center'>
                                    <Title
                                        level="h2"
                                        className="Block-Title text-center"
                                        headerStyleNumb={'14'}
                                        innerHTML={messages[locale].receivePayments.title}
                                    />
                                    <div className='Block-Text text7'>
                                        {messages[locale].receivePayments.description}
                                    </div>

                                    <div id='BotPayment-PaymentsFeatures' class='BotPayment-PaymentsFeatures'>
                                        {messages[locale].receivePayments.listCard.map((item, index) => {
                                            return <SimpleCard key={index} {...item} />
                                        })}
                                    </div>
                                </BlockSection>
                            </div>
                        </div>
                    </Block>
                </div>
            </Block>

            <div className="paymentBotLikes">
                <InfoCardsBlock
                    cardsTitle={messages[locale].infoCardsBlockSecond.cardsTitle}
                    cardsTitleColor="grey"
                    cardsLayout="row"
                    cards={messages[locale].infoCardsBlockSecond.cards}
                    config={{
                        baseBorder: true
                    }}
                />
            </div>

            <Block config={{ baseBorder: false }}>
                <div className="container StepsBotBlock">
                    <BlockSection>
                        <Title
                            innerHTML={messages[locale].stepsBlock.title}
                            color={messages[locale].stepsBlock.color}
                            level="h2"
                            headerStyleNumb="2"
                        />
                        <div class="StepsListHorizontal-overlay">
                            <StepsListHorizontal
                                elements={messages[locale].stepsBlock.list}
                                color = 'second'
                                viewType = 'ol'
                            />
                        </div>
                    </BlockSection>
                </div>

                <div className="container BottonSubmitForm">
                    <SignUpCompanyLeadForm
                        textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
                    />
                </div>
            </Block>

        </Layout>
    )
};

export default OnlineBankPage;
