import iconWorld from "../../images/icons/styled/first/World.svg";
import iconLike from "../../images/icons/styled/first/CurrencyControl.svg";
import iconCheckStar from "../../images/icons/styled/first/CheckInStar.svg";
import links from "../../utils/links";

import botPaymentsOnlineShopping from "../../images/icons/styled/first/botPaymentsOnlineShopping.svg";
import botPaymentsInfoproducts from "../../images/icons/styled/second/botPaymentsInfoproducts.svg";
import botPaymentGames from "../../images/icons/styled/first/botPaymentGames.svg";
import botPaymentTips from "../../images/icons/styled/second/botPaymentTips.svg";
import botPaymentDelivery from "../../images/icons/styled/first/botPaymentDelivery.svg";

import botPaymentNativeForm from "../../images/icons/styled/second/botPaymentNativeForm.svg";
import botPaymentNoSite from "../../images/icons/styled/second/botPaymentNoSite.svg";
import botPaymentSecurity from "../../images/icons/styled/second/botPaymentSecurity.svg";
import botPaymentAuditory from "../../images/icons/styled/second/botPaymentAuditory.svg";
import botPaymentTestMode from "../../images/icons/styled/second/botPaymentTestMode.svg";

import botPaymentFeaturesShop from "../../images/icons/buttons/botPaymentFeaturesShop.svg";
import botPaymentFeaturesBot from "../../images/icons/buttons/botPaymentFeaturesBot.svg";

export default {
    "seo": {
        "title": "Прием платежей в Telegram | Банк 131",
        "description": "Подключайте прием платежей в Telegram c помощью платежного бота Банка 131. Удобное решение для тех, кто ведет бизнес в Telegram."
    },
    "pageHeaderBlock": {
        "title": "Прием платежей<br />в Telegram",
        "text": "Подключайте прием платежей в Telegram<br />c помощью платежного бота Банка 131",
    },

    features: [
        {
            text: "У вас есть магазин в Telegram, и вы хотите подключить оплату?",
            image: botPaymentFeaturesShop
        },
        {
            text: "У нас есть решение – платежный бот Банка 131",
            image: botPaymentFeaturesBot
        }
    ],

    receivePayments: {
        title: 'Принимайте оплату по всей России<br />внутри приложения Telegram',
        description: 'Удобное решение для тех, кто ведет бизнес в Telegram.',
        listCard: [
            {
                "title": 'Интернет-магазины',
                "icon": botPaymentsOnlineShopping,
            },
            {
                "title": 'Инфопродукты',
                "icon": botPaymentsInfoproducts,
            },
            {
                "title": 'Игры',
                "icon": botPaymentGames,
            },
            {
                "title": 'Сервисы чаевых',
                "icon": botPaymentTips,
            },
            {
                "title": 'Доставка еды',
                "icon": botPaymentDelivery,
            }
        ],
    },

    "infoCardsBlockSecond": {
        "cardsTitle": "Вам понравится",
        "cards": [
            {
                "title": 'Нативная форма оплаты',
                "text": 'Покупатели могут оплачивать товар <nobr>и услуги</nobr>, не выходя из приложения. <nobr>К оплате доступны</nobr> российские карты <nobr>Visa, Mastercard, MIR.</nobr>',
                "icon": botPaymentNativeForm,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Тестовый режим',
                "text": 'Вы можете протестировать прием платежей в демо-режиме перед реальным подключением к магазину.',
                "icon": botPaymentTestMode,
                "description": '',
                "theme": 'withoutBackground'
             },
            {
                "title": 'Безопасность транзакций',
                "text": 'Платежи под надежной защитой сертификата PCI DSS первого уровня.',
                "icon": botPaymentSecurity,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Аудитория',
                "text": 'Более 500 млн человек по всему миру пользуются Telegram.',
                "icon": botPaymentAuditory,
                "description": '',
                "theme": 'withoutBackground'
            }
        ]
    },

    "stepsBlock": {
      "title": "Как подключить платежный бот?",
      "titleColor": 'second',
      "listColor": 'second',
      "list": [
          {
              "title": "Откройте платежный бот <nobr><a href=\"https://t.me/Bank_131_bot\" target=\"_blank\" rel=\"noreferrer\">BANK 131</a> в Telegram</nobr> и нажмите «start»",
              "image": '/botPaymentSteps/step1.png',
          },
          {
              "title": 'Перейдите по инструкции <nobr>в @Botfather</nobr>, подключите ваш магазин-бот и выберите «Bank 131»',
              "image": '/botPaymentSteps/step2.png',
          },
          {
              "title": 'Завершите подключение <nobr>в боте BANK 131</nobr> и начните <nobr>прием платежей</nobr>',
              "image": '/botPaymentSteps/step3.png',
          }
      ]
    },

    "submitApplicationBlock": {
        "textSubmit": "Подключить"
    }
}
