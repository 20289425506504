import React from "react";
import PropTypes from "prop-types";

import '../styles/components/featureCard.scss';

const FeatureCard = ({
                         theme = '',
                         image = '',
                         text = ''
                     }) => {
    const classes = React.useMemo(() => {
        let cls = 'FeatureCard';
        cls += ' FeatureCard_theme_' + theme;

        return cls;
    }, [theme]);

    return (
        <div className={classes}>
            <div className="FeatureCard-Inner">
                <div className={`FeatureCard-Content`}>
                    <img className='FeatureCard-Image' src={image} alt="icon" />
                    <h3 className="FeatureCard-Text">
                        {text && <span className="FeatureCard-Description">{text}</span>}
                    </h3>
                </div>
            </div>
        </div>
    )
};

export const FeatureCardPropTypes = {
    theme: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.any.isRequired,
};

FeatureCard.propTypes = FeatureCardPropTypes;

export default FeatureCard;
